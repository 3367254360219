import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Event Booking App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/event-booking-mobile-app/"
    metaDescription="Looking to design an app for ticket or event booking? Our mobile design template is here to fast-track your design flow. Try now with Uizard Pro."
    description="
    h2:Introducing Ticketist, our mobile ticket booking app design template
    <br/>
    Settle down for a once in a lifetime show - luckily, you've already booked your ticket… Ladies and gentlemen, please welcome to the stage, Ticketist, our brand-new <a:https://uizard.io/templates/mobile-app-templates/>mobile app UI template</a> for event booking and ticket management. Ticketist could be the perfect solution for your own design project, and it's yours to try today with Uizard Pro.
    <br/>
    h3:Book your tickets for the biggest design revolution in years
    <br/>
    Want to revolutionize the way you design products? Maybe you are a founder wanting to design your app away from complicated design tools? Uizard is the world's first design tool for non-designers, and empowers you to mockup apps, <a:https://uizard.io/wireframing/>create UI wireframes</a>, and prototype rapidly.
    <br/>
    h3:Front row seats to the democratization of design
    <br/>
    Grab your ticket and come along for the ride as we help you realize your app vision on a screen in front of you, just like magic. If you are looking to create your own ticket reservation app design, Ticketist is the perfect way to skip the design queue.
    "
    pages={[
      "Log in and Sign up screens",
      "Home screen with event overview",
      "Event calendar",
      "Favorite venues",
      "Ticket booking screens and seat reservation",
    ]}
    projectCode="vezm1MyopLUyomq4dVJ1"
    img1={data.image1.childImageSharp}
    img1alt="ticket booking app design template overview"
    img2={data.image2.childImageSharp}
    img2alt="ticket booking app design template event detail screen"
    img3={data.image3.childImageSharp}
    img3alt="ticket booking app design template venue detail screen"
    img4={data.image4.childImageSharp}
    img4alt="ticket booking app design template event overview screen"
    img5={data.image5.childImageSharp}
    img5alt="ticket booking app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/ticket-booking-mobile-app/ticket-booking-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/ticket-booking-mobile-app/ticket-booking-app-event.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/ticket-booking-mobile-app/ticket-booking-app-venues.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/ticket-booking-mobile-app/ticket-booking-app-overview.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/ticket-booking-mobile-app/ticket-booking-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
